<template>
  <div class="main" data-title="档案信息" v-title>
    <van-cell-group>
      <!-- <van-cell :value="listData.realname" title="客户姓名" /> -->
      <van-field
        :error-message="errMsg.realname"
        :rules="[{ required: true, message: '不能为空' }]"
        @input="form.realname = $event.mp.detail"
        label="客户姓名"
        placeholder="请输入"
        required
        v-model="form.realname"
      />
      <van-cell :value="listData.mobile" title="手机号码" />
      <van-cell :value="listData.last_trace_date" title="最近跟踪时间" />
      <van-cell :value="listData.seller_uid_text" title="销售顾问" />
      <van-cell title="来电/店">{{
        listData.income_type == '1' ? '来店' : '来电'
      }}</van-cell>
      <van-cell :value="listData.income_date" title="进店日期" />
      <van-cell :value="listData.income_time_section" title="来访时间段" />
      <van-cell title="进离店时间"
        >{{ listData.income_time_begin }} -
        {{ listData.income_time_end }}</van-cell
      >
      <van-cell title="留店时长"
        >{{ listData.income_time_duration }}分钟</van-cell
      >
      <van-cell title="来店人数">{{ listData.income_number }}人</van-cell>
      <van-cell :value="listData.client_type_text" title="客户类型" />
      <van-cell :value="listData.wechat" title="微信号" />
      <van-cell :value="listData.sex == '1' ? '男' : '女'" title="性别" />
      <van-cell
        :value="listData.is_first_income == '1' ? '首次' : '再次'"
        title="客户属性"
      />
      <van-cell :value="listData.region_text" title="区域" />
      <van-cell
        :value="listData.is_invite_income == '1' ? '是' : '否'"
        title="是否邀约"
      />
      <van-cell
        :value="listData.has_seat == '1' ? '是' : '否'"
        title="是否入座"
      />
      <van-cell
        :value="listData.has_offer == '1' ? '是' : '否'"
        title="是否报价"
      />
      <!-- <van-cell :value="listData.is_vip == '1' ? '是' : '否'" title="是否重点客户" /> -->
      <van-cell title="是否重点客户" title-width="90px">
        <van-radio-group v-model="form.is_vip">
          <div class="flex">
            <van-radio :name="1">是</van-radio>
            <van-radio :name="2">否</van-radio>
          </div>
        </van-radio-group>
      </van-cell>

      <van-cell
        :value="listData.has_trial == '1' ? '是' : '否'"
        title="是否试驾"
      />
      <van-cell :value="listData.trial_memo" title="试驾备注" />
      <van-cell
        :value="listData.has_pick == '1' ? '是' : '否'"
        title="现提车辆"
      />
      <van-cell :value="listData.income_purpose_text" title="来店目的" />
      <field-select
        :columns="cfgListArr('demandCarType')"
        :label="'意向车型'"
        @change="getAttribute"
        isLink
        placeholder="请选择"
        required
        v-model="form.target_model"
      ></field-select>
      <field-select
        :columns="columns"
        :label="'车型说明'"
        isLink
        placeholder="请选择"
        required
        v-model="form.attribute"
      ></field-select>
      <field-select
        :columns="cfgListArr('gearboxType')"
        :label="'变速箱类型'"
        isLink
        placeholder="请选择"
        v-model="form.transmission"
      ></field-select>
      <field-select
        :columns="cfgListArr('displacement')"
        :label="'需求排量'"
        isLink
        placeholder="请选择"
        v-model="form.displacement"
      ></field-select>
      <field-select
        :columns="cfgListArr('outsideColor')"
        :label="'外观颜色'"
        isLink
        placeholder="请选择"
        v-model="form.outer_color"
      ></field-select>
      <field-select
        :columns="cfgListArr('insideColor')"
        :label="'内饰颜色'"
        isLink
        placeholder="请选择"
        v-model="form.inner_color"
      ></field-select>
      <div style="padding: 16px">
        <van-button
          @click="onSubmit"
          block
          native-type="submit"
          round
          type="info"
          >更新客户需求</van-button
        >
      </div>
      <van-cell :value="listData.clue_source_text" title="线索渠道" />
      <van-cell :value="listData.marketing_analysis_text" title="营销分析" />
      <van-cell :value="listData.client_cate_text" title="客户类别" />
      <van-cell
        :value="listData.has_effective == '1' ? '是' : '否'"
        title="客户有效性"
      />
      <van-cell :value="listData.buy_type_text" title="购车类型" />
      <van-cell :value="listData.used_model" title="二手车型" />
      <van-cell
        :value="listData.used_arrived == '1' ? '是' : '否'"
        title="二手车到店"
      />
      <van-cell
        :value="listData.used_surveyed == '1' ? '是' : '否'"
        title="是否勘察"
      />
      <van-cell :value="listData.valuator" title="评估师" />
      <van-cell :value="listData.demand_memo" title="简述" />
    </van-cell-group>
  </div>
</template>
<script>
import FieldSelect from '@/components/FieldSelect.vue'
import { sellList, dateFormat, getTimeInterval, cfgList } from '@/unit/unit.js'
export default {
  components: {
    FieldSelect,
  },
  data() {
    return {
      listData: {},
      car_type: '',
      columns: [],
      form: {
        client_id: 0,
        target_model: '',
        attribute: '',
        transmission: '',
        displacement: '',
        outer_color: '',
        inner_color: '',
        realname: '',
        is_vip: '',
      },
      errMsg: {
        target_model: '',
        attribute: '',
      },
    }
  },
  mounted() {
    this.form.client_id = parseInt(this.$route.query.client_id)
    this.view()
  },
  methods: {
    getUserName(id) {
      let userList = this.$store.state.userList
      return userList[id][0]
    },
    onSubmit() {
      if (this.form.target_model == '') {
        this.errMsg.target_model = '请选择'
      } else {
        this.errMsg.target_model = ''
      }
      if (this.form.attribute == '') {
        this.errMsg.attribute = '请选择'
      } else {
        this.errMsg.attribute = ''
      }

      if (this.errMsg.target_model == '' && this.errMsg.attribute == '') {
        this.$axios.post('/client/edit', this.form).then((res) => {
          if (res.data.code == 200) {
            this.$dialog({ message: res.data.msg })
          }
        })
      }
    },
    getAttribute() {
      this.form.attribute = ''
      let cfgList = this.$store.state.cfgList
      let column2 = []
      cfgList.attribute.map((item) => {
        if (this.form.target_model == parseInt(item.f2)) {
          let temp_json = {
            value: item.sys_id,
            text: item.f1,
          }
          column2.push(temp_json)
        }
      })

      this.columns = column2
    },
    cfgListArr(type) {
      return cfgList(type)
    },
    changeCarType(val) {
      let arr = val.split('/')
      this.form.target_model = parseInt(arr[0])
      this.form.attribute = arr[0] == '' ? '' : parseInt(arr[1])
    },
    getCfgText(cid) {
      if (cid == '') {
        return ''
      }
      let id = cid
      if (typeof id == 'string') {
        id = parseInt(id)
      }

      let cfgData = this.$store.state.cfgData

      if (cfgData[id] == undefined) {
        return ''
      } else {
        return cfgData[id][0]
      }
    },
    setFieldValue(event, field) {
      this.form[field] = event.mp.detail
    },
    view(client_id) {
      this.$axios
        .post('/client/detail', {
          client_id: this.form.client_id,
        })
        .then((res) => {
          this.form.target_model = parseInt(res.data.data.target_model)
          this.form.is_vip = parseInt(res.data.data.is_vip)
          this.form.realname = res.data.data.realname

          this.getAttribute()
          this.form.attribute = parseInt(res.data.data.attribute)
          this.form.transmission =
            res.data.data.transmission != ''
              ? parseInt(res.data.data.transmission)
              : ''
          this.form.displacement =
            res.data.data.displacement != ''
              ? parseInt(res.data.data.displacement)
              : ''
          this.form.outer_color =
            res.data.data.outer_color != ''
              ? parseInt(res.data.data.outer_color)
              : ''
          this.form.inner_color =
            res.data.data.inner_color != ''
              ? parseInt(res.data.data.inner_color)
              : ''

          let resData = res.data.data
          resData.seller_uid_text = this.getUserName(resData.seller_uid)

          resData.client_type_text = this.getCfgText(resData.client_type)
          resData.region_text = this.getCfgText(resData.region)
          resData.income_purpose_text = this.getCfgText(resData.income_purpose)
          resData.target_model_text = this.getCfgText(resData.target_model)
          // resData.attribute = this.getCfgText(resData.attribute)
          resData.attribute = parseInt(resData.attribute)

          resData.transmission_text = this.getCfgText(resData.transmission)
          resData.displacement_text = this.getCfgText(resData.displacement)
          resData.outer_color_text = this.getCfgText(resData.outer_color)
          resData.inner_color_text = this.getCfgText(resData.inner_color)
          resData.clue_source_text = this.getCfgText(resData.clue_source)
          resData.marketing_analysis_text = this.getCfgText(
            resData.marketing_analysis
          )
          resData.client_cate_text = this.getCfgText(resData.client_cate)
          resData.buy_type_text = this.getCfgText(resData.buy_type)
          this.listData = resData
        })
    },
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  van-radio {
    margin-right: 40px;
  }
}
</style>